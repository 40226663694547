const trimSVG = (s) => {
  // BOM junk can throw this render off. Find the SVG tag.
  const i = s.indexOf("<svg");

  return s.substring(i);
};

function Svg({ svg, alt = "", size = 64, animation }) {
  return (
    <div style={{ width: size, height: size, borderRadius: 9999, overflow: "hidden" }}>
      <img
        className={animation}
        src={`data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(trimSVG(svg))))}`}
        alt={alt}
        width={size}
        height={size}
      />
    </div>
  );
}

export default Svg;
