import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import ReactGA from "react-ga4";
import * as Sentry from "@sentry/react";

import LoadingIndicator from "./components/LoadingIndicator";
import { Button, Container, Paper, Typography } from "@material-ui/core";

ReactGA.initialize("G-X93XN8QYZK", {
  debug: false,
});
ReactGA.set({ anonymizeIp: true });

Sentry.init({
  dsn:
    "https://551a57c463c644fd861b69fe8ebd46da@o177043.ingest.sentry.io/5684499",
});

// Lazy loading
const Main = React.lazy(() => import("./views/Main"));

const theme = createMuiTheme({
  typography: {
    fontFamily: "Raleway, sans-serif",
    allVariants: {
      color: "#333",
    },
  },
  palette: {
    primary: {
      main: "#965ede",
    },
    secondary: {
      main: "#8bc34a",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  loading: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(8, 0),
  },
  errorPaper: {
    marginTop: theme.spacing(4),
    "& > *": {
      margin: theme.spacing(2),
    }
  },
}));

function App() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Sentry.ErrorBoundary
        fallback={({ resetError }) => (
          <Container>
            <Paper className={classes.errorPaper}>
              <Typography variant="h4">
                Oh no, this was unexpected...
              </Typography>
              <Typography variant="body1">
                  This error has been reported to our engineering team. Click
                  the button below to reload the page.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  resetError();
                  window.history.replaceState({}, null, "/glob");
                  window.location.reload();
                }}
              >
                RELOAD
              </Button>
            </Paper>
          </Container>
        )}
      >
        <Suspense
          fallback={
            <div className={classes.loading}>
              <LoadingIndicator size={100} animation="infinite" />
            </div>
          }
        >
          <Switch>
            <Route path="/:industry" component={Main} />
            <Route path="/" component={Main} />
          </Switch>
        </Suspense>
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
